import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const itemHeight = 100;

const StyledContainer = styled.div`
	width: 100%;
	height: ${itemHeight}px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border: 1px solid black;
	border-radius: 10px;
	overflow: hidden;
	cursor: pointer;
	&:hover {
		box-shadow: 0px 10px 15px rgba(96, 100, 112, 0.2);
	}
`;

const linkStyles = {
	height: `${itemHeight}px`,
	width: "100%",
	textDecoration: "none",
};

const ImageContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	height: ${itemHeight}px;
	width: ${itemHeight}px;
`;

const TextContainer = styled.div`
	height: 80%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
	padding-left: 20px;
`;

const Item = ({ image, title, tools, endpoint }) => (
	<Link to={`/portfolio/${endpoint}`} style={linkStyles}>
		<StyledContainer>
			<ImageContainer>
				<img alt="" src={image} height={itemHeight - 2} />
			</ImageContainer>
			<TextContainer>
				<h2 style={{ margin: "0", textDecoration: "none" }}>{title}</h2>
				<p style={{ textDecoration: "none" }}>
					<span style={{ fontWeight: "500" }}>Tools:</span> {tools}
				</p>
			</TextContainer>
		</StyledContainer>
	</Link>
);

export default Item;
