import React from "react";
import styled from "styled-components";

import MeasureLogo from "../../images/Measure Logo.png";
import CorvallisLogo from "../../images/Corvallis Logo.png";
import JHooks from "../../images/J-Hooks.png";
import Jarvis from "../../images/Jarvis.png";
import HeyHue from "../../images/Hue.png";

import Layout from "../../components/layout";
import PortfolioItem from "../../components/portfolioItem";

const StyledSection = styled.section`
	.title {
		margin: 0;
	}
	.subtitle {
		margin-top: 0;
	}
	.highlighted {
		font-weight: 700;
		background-color: #cdf3e1;
	}
	.description {
		margin: 20px 0px;
	}
	.content-section {
		margin: 33px 0px;
	}
`;

const items = [
	{
		image: MeasureLogo,
		title: "Measure",
		tools: "React, SCSS, Visx",
		endpoint: "measure",
	},
	{
		image: CorvallisLogo,
		title: "Corvallis Off Campus",
		tools: "React Native, NodeJS, Selenium, BeautifulSoup",
		endpoint: "corvallis",
	},
	{
		image: HeyHue,
		title: "HeyHue",
		tools: "Commander, Inquirer",
		endpoint: "hue",
	},
	{
		image: JHooks,
		title: "J-Hooks",
		tools: "React, npm",
		endpoint: "j-hooks",
	},
	{
		image: Jarvis,
		title: "Jarvis",
		tools: "Python, Google Speech-to-Text API",
		endpoint: "jarvis",
	},
];

const PortfolioPage = () => (
	<Layout>
		<StyledSection id="portfolio">
			{items.map((item, index) => (
				<div style={{ marginBottom: "30px" }} key={index}>
					<PortfolioItem
						image={item.image}
						title={item.title}
						tools={item.tools}
						endpoint={item.endpoint}
					/>
				</div>
			))}
		</StyledSection>
	</Layout>
);

export default PortfolioPage;
